@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';


































































































































.Modal_title {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 21px;
}

.Api_input-hint {
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  margin: 10px 0 20px;
  color: #fff;
}

.Modal_warn {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;
  span {
    color: #ec5656;
  }
}

.Modal_content-row {
  margin-bottom: 20px;
  label {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: left;
    margin-bottom: 8px;
    svg {
      margin-right: 5px;
    }
  }
  .Modal_content-value {
    font-size: 14px;
    line-height: 16px;
    color: rgba(#fff, 0.44);
    text-align: left;
  }
  .Modal_content-value-flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .Modal_copy-btn {
    margin-left: 10px;
  }
}
