@import '@/assets/scss/_variables.scss';

.Api_container {
  border-bottom: 1px solid rgba(#fff, 0.1);

  &--last {
    margin-top: 20px;
    border-bottom: none;
  }

  form {
    width: 100%;
  }
}

.Api_row {
  &--halfWidth {
    @media only screen and (min-width: $tablet-width) {
      max-width: 50%;
    }
  }

  &--flex {
    display: flex;
    justify-content: flex-start;
  }
}

.Api_button {
  background: rgba(#2af3f3, 0.21);
  box-shadow: none;
  outline: none;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  min-width: 134px;
  height: 46px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 4px;

  .Api_button-text {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #2af3f3;
    display: inline-block;
    flex: 1;
  }

  .Api_button-box {
    display: inline-block;
    background: #2af3f3;
    box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
    border-radius: 2px;
    height: 38px;
    width: 38px;
    line-height: 38px;
    font-weight: 400;
    text-align: center;
    color: #2d4d70;
  }

  transition: 0.1s all ease-in-out;

  &:hover {
    background: #2af3f3;

    .Api_button-text {
      color: #2d4d70;
    }

    .Api_button-box {
      color: #2af3f3;
      background: #2d4d70;
    }
  }

  &:active {
    transform: scale(0.95);
  }
}

.Modal_title {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 21px;
}

.Api_input-hint {
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  margin: 10px 0 20px;
  color: #fff;
}