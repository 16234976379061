@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';


































































.Modal_img {
  margin: 30px 0;
}
.Modal_warn {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 10px;
  span {
    color: #ec5656;
  }
  strong {
    color: #2af3f3;
  }
}

.Modal_content-row {
  margin-bottom: 20px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  label {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: left;
    margin-bottom: 8px;
    svg {
      margin-right: 5px;
    }
  }
}

.Modal_btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    flex: 0 1 49%;
  }
}
