@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';


























































































@import '@/assets/scss/_variables.scss';

.apiKey_box-inner {
  position: absolute;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  background-image: url('../../../../../assets/img/_common/lock-img.svg');
  background-position: center;
  background-size: 25px;
  background-repeat: no-repeat;

  @media only screen and (min-width: $tablet-width) {
    width: calc(100% - 44px);
    height: calc(100% - 44px);
  }
}
